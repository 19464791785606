var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',{staticClass:"container px-0"},[_c('v-row',{staticClass:"title-row",class:`${
        _vm.$vuetify.breakpoint.mdAndDown ? 'flex-column flex-nowrap' : ''
      }`},[_c('v-col',{staticClass:"d-flex align-center title-column"},[_c('h4',{staticClass:"font-weight-light"},[_vm._v(_vm._s(_vm.$t('heading.hosting.summary.title')))])]),(
          !_vm.loading &&
          _vm.serverAccount.privileges.includes('hosting.control_panel_sso')
        )?_c('v-col',{staticClass:"d-flex align-center"},[_c('v-btn',{staticClass:"font-weight-bold v-btn--outlined",attrs:{"elevation":"0","block":"","href":'#',"target":'_blank',"loading":_vm.controlpanelSsoLoading},on:{"click":function($event){$event.preventDefault();return _vm.controlPanelSsoLogin(_vm.serverAccount.id)}}},[_vm._v(" "+_vm._s(_vm.$t("button.login.controlPanel"))+" ")])],1):_vm._e()],1)],1),_c('v-container',{staticClass:"mt-3 px-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('hosting-nameservers-card',{attrs:{"serverAccount":_vm.serverAccount,"loading":_vm.loading,"nameservers":_vm.nameservers}}),_c('hosting-instances-card',{attrs:{"serverAccount":_vm.serverAccount,"loading":_vm.loading}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[(_vm.loading)?_c('hosting-summary-card-skeleton'):_c('hosting-summary-card',{attrs:{"usageData":_vm.planUsage,"serverAccount":_vm.serverAccount,"availableSpace":_vm.planUsageHeader},scopedSlots:_vm._u([{key:"action",fn:function(){return [(_vm.serverAccount.plan_upgrade_link)?_c('v-btn',{attrs:{"href":_vm.serverAccount.plan_upgrade_link +
                '?paupgradeserviceid=' +
                _vm.serverAccount.service_id,"target":"_blank","x-small":"","text":"","color":"info"}},[_c('span',{staticClass:"font-weight-600"},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"16"}},[_vm._v("$restorebackup")]),_vm._v(" "+_vm._s(_vm.$t('button.upgrade'))+" ")],1)]):_vm._e()]},proxy:true}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }